export class HeaderObserver {
    constructor() {
        this.observer = this.buildObserver()
    }

    startObservingHeader() {
        let header = document.getElementById("clientHeader")
        if (header != null) this.observer.observe(header)
    }

    buildObserver() {
        return new IntersectionObserver(
            ([event]) => event.target.toggleAttribute("stuck", event.intersectionRatio < 1),
            { threshold: [1] }
        )
    }
}
