import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  copy(event) {
    let button = this.buttonTarget;

    navigator.clipboard.writeText(event.params.link);
    button.style.backgroundColor = "#28a745";
    setTimeout(function() { button.style.backgroundColor = ""; }, 700);
  }

  open(event) {
    window.open(event.params.link);
  }
}
